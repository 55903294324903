import React from 'react'
import localization from '../localization/localization'
import CardComponent from '../cardComponent/CardComponent'
import AboutUsComponent from '../aboutUs/AboutUsComponent'
import CarouselComponent from './Carousel'
import CountDown from './CountDown'
import { NavLink } from "react-router-dom"
import './Intro.css'

const Intro = (props) => {
  const carouselImages = [
    process.env.PUBLIC_URL + '/media/Carousel1.jpg',
    process.env.PUBLIC_URL + '/media/Carousel2.jpg',
    process.env.PUBLIC_URL + '/media/Carousel3.jpg',
    process.env.PUBLIC_URL + '/media/Carousel4.jpg',
    process.env.PUBLIC_URL + '/media/Carousel5.jpg',
    process.env.PUBLIC_URL + '/media/Carousel6.jpg',
    process.env.PUBLIC_URL + '/media/Carousel7.jpg',
  ];

  return (
    <div className="index-body">
      <div className="parallax-body">
        <CarouselComponent carouselImages={carouselImages} />
        <h1>
          <span>{localization.home.fri}</span>
          <br/>
          <span>{localization.home.sun}</span>
          <br/>
          <span>{localization.home.sat}</span>
          <br/>
          <span>{localization.home.info.mtb}</span>
          <br/>
          <span><NavLink to="/shop">{localization.home.info.shop}</NavLink> </span>
          <br/>
          <CountDown />
        </h1>
        <div className="levitating-arrow-body">
          <p>
            <i className="levitating-arrow"></i>
          </p>
        </div>
      </div>
      <div className="parallax-scroll">
        <CardComponent />
        <AboutUsComponent />
      </div>
    </div>
  );
};

export default Intro;